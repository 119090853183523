import React, { useState, useEffect, createContext } from "react";
import styled from "@emotion/styled";
import { NavLink, Outlet, useLoaderData } from "react-router-dom";
import { Fab, useMediaQuery, Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { ThemeProvider as ThemeProviderSC } from "@emotion/react";
import ScrollToHash from "../components/ScrollToHash";

import ScrollTop from "../components/ScrollTop";
import { Event, initGA } from "../components/Tracking";

import { theme } from "../Theme";

import "../App.css";

import { MainNavigation, MobileMenu, Footer } from "../components/index.js";

export const ClearwaterContext = createContext();

function AppLayout(props) {
  const loaderData = useLoaderData();
  // useEffect(() => initGA("G-5HYG31PEMB"), []);

  const showTextNav = useMediaQuery(theme.breakpoints.up("lg"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isTouch, setIsTouch] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isXsmall = useMediaQuery(theme.breakpoints.only("xs"));
  const [newsId, setNewsId] = useState();
  const [newsDetail, setNewsDetail] = useState();

  const [NewsPageData, setNewsPageData] = useState();

  const [BuilderData, setBuilderData] = useState();

  //TODO This is the signature to use for setting the hero in this context. Need to
  // setHero({ heroTitle: res.data.Hero.HeroTitle, heroImage: res.data.Hero.HeroImage[0] });

  const handleOnTouchStart = event => {
    setIsTouch(true);
  };

  const contentParameters = [
    {
      localStorageVariableName: "cw-properties",

      // component: Layout,
    },
    {
      localStorageVariableName: "cw-showhome",

      // component: Layout,
    },
    {
      localStorageVariableName: "cw-home",
      path: "/",
      pageTitle: "Welcome",
      pageId: "home",
      name: "Home",
      altText: "Clearwater Home",
      // component: Layout,
    },
    {
      localStorageVariableName: "cw-lh",
      path: "/living-here",
      link: "/living-here",
      pageTitle: "Living Here",
      pageId: "living-here",
      name: "Living Here",
      altText: "Living Here",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      localStorageVariableName: "cw-builder",
      path: "/builders",
      link: "/builders",
      pageTitle: "Builders",
      pageId: "builders",
      name: "Builders",
      altText: "Builders",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      localStorageVariableName: "cw-retail",
      path: "/retail",
      link: "/retail",
      pageTitle: "Retail",
      pageId: "retail",
      name: "Retail",
      altText: "Retail",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      localStorageVariableName: "cw-forlease",
      path: "/for-lease",
      link: "/for-lease",
      pageTitle: "Homes For Lease",
      pageId: "for-lease",
      name: "Homes For Lease",
      altText: "for-lease",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      localStorageVariableName: "cw-clubhouse",
      path: "/community-clubhouse",
      link: "/community-clubhouse",
      pageTitle: "Community Clubhouse",
      pageId: "community-clubhouse",
      name: "Community Clubhouse",
      altText: "Community Clubhouse",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      localStorageVariableName: "cw-gallery",
      path: "/gallery",
      link: "/gallery",
      pageTitle: "Gallery",
      pageId: "gallery",
      name: "Gallery",
      altText: "Gallery",
      navComponent: NavLink,
    },
    {
      localStorageVariableName: "cw-news",
      path: "/news",
      link: "/news",
      pageTitle: "News",
      pageId: "news",
      name: "News",
      altText: "News",
      navComponent: NavLink,
    },
    {
      localStorageVariableName: "cw-faq",
      path: "/faq",
      link: "/faq",
      pageTitle: "FAQ",
      pageId: "faq",
      name: "FAQ",
      altText: "FAQ ",
      navComponent: NavLink,
    },
    {
      localStorageVariableName: "cw-visit",
      path: "/visit-us",
      link: "/visit-us",
      pageTitle: "Visit Us",
      pageId: "visit-us",
      name: "Visit Us",
      altText: "Visit Us",
      navComponent: NavLink,
    },
    // {
    //
    //
    //
    //   localStorageVariableName: "cw-gallery",
    //   path: "/gallery",
    //   link: "/gallery",
    //   pageTitle: "Clearwater Park Gallery",
    //   pageId: "gallery",
    //   name: "Gallery",
    //   altText: "Clearwater Park Gallery",
    //   navComponent: NavLink,
    //   // component: Layout,
    // },
  ];

  // useEffect(() => {
  //   contentParameters.forEach(
  //     async ({ apiPath, setter, localStorageVariableName }, index, array) => {
  //       if (apiPath) {
  //         try {
  //           await getStrapiContent(apiPath, setter, localStorageVariableName).then(res => {
  //             if (apiPath === "/api/home/" && res?.createdAt) {
  //               setIsLoadingData(false);
  //               return true;
  //             }
  //           });
  //         } catch (error) {
  //           setError(error);
  //           return false;
  //         }
  //       }
  //     }
  //   );
  // }, []);

  useEffect(() => {
    const nd = NewsPageData?.PageContent[1].NewsItems.filter(element => {
      if (element.id === Number(newsId)) {
        return element;
      }
    })[0];
    setNewsDetail(nd);
  }, [NewsPageData]);

  const handleNewsClick = e => {
    setNewsId(Number(e?.currentTarget?.dataset?.id));

    const nd = NewsPageData?.PageContent[1].NewsItems.filter(element => {
      if (element.id === Number(e.currentTarget?.dataset?.id)) {
        return element;
      }
    })[0];

    setNewsDetail(nd);
    scrollToTop();
    return true;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  return (
    <ThemeProviderSC theme={theme}>
      <ScrollToHash />;
      <ThemeProvider theme={theme}>
        <Container className="App" maxWidth="xl" disableGutters onTouchStart={handleOnTouchStart}>
          {showTextNav && (
            <MainNavigation
              scrollToTop={scrollToTop}
              setIsModalOpen={setIsModalOpen}
              theme={theme}
              hasBuilderData={BuilderData?.createdAt ? true : false}
              contentParameters={contentParameters}
            />
          )}
          {!showTextNav && (
            <MobileMenu
              scrollToTop={scrollToTop}
              contentParameters={contentParameters}
              theme={theme}
              hasBuilderData={BuilderData?.createdAt ? true : false}
            />
          )}
          <ContentContainer disableGutters maxWidth="xl">
            <ClearwaterContext.Provider value={loaderData}>
              <Outlet />
            </ClearwaterContext.Provider>
          </ContentContainer>
          {!isXsmall && <Footer isSmall={isSmall} isXsmall={isXsmall} />}
          {isXsmall && <Footer isSmall={isSmall} isXsmall={isXsmall} />}
        </Container>
        <ScrollTop {...props}>
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <ArrowUpward />
          </Fab>
        </ScrollTop>
      </ThemeProvider>
    </ThemeProviderSC>
  );
}

const ContentContainer = styled(Container)`
  margin-top: 55px;
  ${props => props.theme.breakpoints.up("sm")} {
    margin-top: 66px;
  }
  ${props => props.theme.breakpoints.up("md")} {
    margin-top: 66px;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    margin-top: 110px;
  }
`;

export default AppLayout;
