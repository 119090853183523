import React, { useState } from "react";
import styled from "@emotion/styled";
import Wave from "../assets/WaveLightLightBlue.svg";
import { Typography, Grid, Button } from "@mui/material";
import Description from "./Description";
import MailChimpForm from "./MailChimpForm/index";

export default function Registration(props) {
  const [messageSent, setMessageSent] = useState(false);

  return (
    <Wrapper
      messageSent={messageSent}
      justifyContent="flex-start"
      alignItems="center"
      alignContent="center"
      direction="column"
      wrap={"nowrap"}
      container
      id="register"
      pb={{ xs: "2rem", md: "3rem" }}
    >
      <Description
        Copy={props?.Copy}
        {...{
          options: {
            wrapperStyle: {
              sx: {
                padding: { xs: "5em 1em 2em", md: "10em 2em 2em" },
                "p,h3": {
                  maxWidth: "934px",
                },
              },
            },
          },
        }}
      />

      {messageSent && (
        <>
          <Typography align="center" style={{ marginBottom: 0 }} variant="h1">
            Awesome!
          </Typography>
          <Typography align="center" variant="h2" paragraph>
            We'll be in touch.
          </Typography>
          <Typography align="center" color={"common.black"} variant="h3" paragraph>
            Check your email for a confirmation link from us.
          </Typography>
          <Typography align="center" color={"common.black"} variant="h3" paragraph>
            You'll need to confirm your registration to complete the process.
          </Typography>
          <Button href="/builders" variant={"contained"} sx={{ mt: 5 }}>
            Learn more about our builders.
          </Button>
        </>
      )}
      {!messageSent && <MailChimpForm setMessageSent={setMessageSent} />}
    </Wrapper>
  );
}

const Wrapper = styled(({ messageSent, ...props }) => <Grid {...props} />)`
  color: ${({ theme }) => theme.palette.primary.red};
  background-color: ${({ theme }) => theme.palette.secondary.lightlight};
  ${({ theme }) => theme.breakpoints.up("md")} {
    background-color: ${({ theme }) => theme.palette.common.white};
    background-image: url(${({ messageSent }) => (!messageSent ? Wave : null)});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top/center;
  }
`;
